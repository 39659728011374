<template>
    <div>
        <div class="row" >
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom">
                <label class="mb-2"> Format d'affichage: </label>
                <div>
                    <el-radio v-model="radio" label="jour">Jours</el-radio>
                    <!-- <el-radio v-model="radio" label="semaine">Semaines</el-radio> -->
                    <el-radio v-model="radio" label="mois">Mois</el-radio>
                </div>
            </div>
            <div v-if="radio == 'jour'" class="col-xl-6 col-lg-4 col-md-5 col-sm-6 col-xs-6 mb-1">
                    <label>Choisir la période : </label>
                    <div class="data-picker" style="width:100%">
                        <el-date-picker
                            v-model="drp_jour"
                            type="daterange"
                            unlink-panels
                            range-separator="-"
                            format="dd/MM/yyyy"
                            @change="loadData()"
                            :clearable="clearable"
                            :picker-options="pickerOptions_j">
                        </el-date-picker>
                    </div>
            </div>
            <div v-if="radio == 'mois'" class="col-xl-6 col-lg-4 col-md-5 col-sm-6 col-xs-6 mb-1">
                    <label>Choisir la période : </label>
                    <div class="data-picker" style="width:100%">
                        <el-date-picker
                            v-model="drp_month"
                            format="dd/MM/yyyy"
                            type="monthrange"
                            align="right"
                            unlink-panels
                            @change="loadData()"
                            range-separator="à"
                            start-placeholder="Mois de début"
                            end-placeholder="Mois de fin"
                            :picker-options="pickerOptions_m">
                        </el-date-picker>
                    </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom">
                <label class="mb-2">{{titleChoice}} : </label>
                <div class="data-picker" style="width:100%">
                    <ui-select
                        :placeholder="titleChoice"
                        :options="agencesOption"
                        @change="loadData()"
                        v-model="agenceSelect"
                    ></ui-select>
                </div>
            </div>
        </div>
        <div class="row" v-if="empty == 1">
            <el-divider></el-divider>
            <el-empty description="Pas de données pour la période"></el-empty>
        </div>
        <div v-else class="row m-2 p-2">
            <!-- <el-divider></el-divider> -->
            <h4><i> Graphique en minutes du retard </i></h4>
            <BarchartMulti v-bind:data1="data1" v-bind:data2="data2"/>
            <el-divider></el-divider>
            <h4><i> Tableau global </i></h4>
            <div class="row m-4" style="display: flex; align-content: center; align-items: center; max-width:600px">
                <table><tr>
                    <th style="border:none; text-align: center;">moins de 5 min</th><th style="width: 30px;height: 30px;border-radius: 50%;border: none; background-color: #6aa84f;"></th>
                    <th style="border:none; text-align: center;">| Entre 5 et 15 min</th><th style="width: 30px;height: 30px;border-radius: 50%;border: none; background-color: #f6b26b;"> </th>
                    <th style="border:none; text-align: center;">| Plus de 15 min</th><th style="width: 30px;height: 30px;border-radius: 50%;border: none; background-color: #e06666;"> </th>
                </tr></table>
            </div>

            <TablesDates v-bind:title="title" v-bind:headers="headers" v-bind:items="items" />
        </div>
    </div>
</template>

<script>

    import Menu from './compos/menu.vue';
    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';

    import BarchartMulti from '@/components/ApexChart/BarchartMulti.vue';
    import TablesDates from '@/components/Tables/RegularTableHeaderAdd.vue';



    export default {
        components: {
            Menu,
            BarchartMulti,
            TablesDates,
        },

        props: ['agencesOption','agenceSelect'],

        data () {
            return {
                type : 'agence',
                titleChoice: "Choisir l'agence",
                title: 'Récapitulatif sur la période',
                data1 : [],
                data2 : [],
                datatable : [],
                users: ['kevin'],
                selected_user: [],
                radio: 'jour',
                status: '',
                empty: 1,
                statusSelect : [],
                periode: 'Choisir la periode',
                start:  moment().subtract(12, 'month').startOf('month').toDate(),
                end: moment().toDate(),
                clearable: true,
                drp_jour : [moment().subtract(1, 'isoweek').startOf('isoweek').toDate(), moment().toDate()],
                drp_month:[moment().subtract(4, 'month').endOf('month').toDate(), moment().endOf('month').toDate()],

                pickerOptions_j: {
                    shortcuts: [{
                        text: 'Mois precedent',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]);
                        }
                    }, 
                    {
                        text: 'Mois en cours',
                        onClick(picker) {
                            picker.$emit('pick', [moment().startOf('month').toDate(), new Date()]);
                        }
                    }, 
                    {
                        text: 'Semaine precedente',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'week').startOf('isoWeek').toDate(), moment().subtract(1, 'week').endOf('isoWeek').toDate()]);
                        }
                    }, 
                    {
                        text: 'Semaine en cours',
                        onClick(picker) {
                            picker.$emit('pick', [moment().startOf('isoWeek').toDate(), new Date()]);
                        }
                    }, 
                    {
                        text:'Avant hier',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(2, 'day').toDate(), moment().subtract(2, 'day').toDate()]);
                        }
                    }, 
                    {
                        text: 'Hier',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'day').toDate(), moment().subtract(1, 'day').toDate()]);
                        }
                    }, {
                        text: 'Aujoudhuit',
                        onClick(picker) {
                            picker.$emit('pick', [moment().toDate(), new Date()]);
                        }
                    }],
                    format:'dd MM yyyy',
                    firstDayOfWeek: 1,
                    disabledDate: this.disabledDueDate
                },

                pickerOptions_m: {
                    shortcuts: [{
                        text: 'Ce mois',
                        onClick(picker) {
                        picker.$emit('pick', [moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
                        }
                    }, {
                        text: 'Cette année',
                        onClick(picker) {
                        picker.$emit('pick', [moment().startOf('year').toDate(), moment().endOf('month').toDate()]);
                        }
                    }, {
                        text: 'Les derniers 6 mois',
                        onClick(picker) {
                        picker.$emit('pick', [moment().subtract(5, 'month').startOf('month').toDate(), moment().toDate()]);
                        }
                    }],
                    format:'dd MM yyyy',
                    firstDayOfWeek: 1,
                    disabledDate: this.disabledDueDate
                },

                headers: [
                    {
                    sortable: true,
                    text: 'Journée',
                    value: 'jour',
                    },
                    {
                    sortable: true,
                    text: 'Nom',
                    value: 'name',
                    },
                    {
                    sortable: true,
                    text: 'Agence',
                    value: 'agence',
                    },
                    {
                    sortable: true,
                    text: "heure d'arrivé",
                    value: 'date',
                    },
                    {
                    sortable: true,
                    text: 'retard (minutes)',
                    value: 'minute',
                    }
                    
                ],
                items: [],
            }
        },
        watch: {
            radio : function() {
                this.loadData();
            },
            agenceSelect : function() {
                this.loadData();
            }
        },

        methods: {
            async loadData() {
                NProgress.start();
                try {

                    if (this.radio == 'jour') { this.drp = this.drp_jour } else if (this.radio == 'mois') { this.drp = this.drp_month }

                    let crm = {
                        agence : this.agenceSelect.value,
                        start: moment(new Date(this.drp[0])).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                        end: moment(new Date(this.drp[1])).endOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                    };

                    const response = await axios.post('v1/xperf/report/GbAgencelog', crm);
                    var res = response.data;

                    if(res.length > 0){ 
                        this.empty = 0;
                        this.BuildApexChart(res);
                        this.BuildTable(res);
                    } 
                    else {this.empty = 1;}
                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec de recuperation des informations "
                    });
                }

                NProgress.done();
            },

            BuildApexChart(data) {

                this.data1 = [];
                this.data2 = [];
                var all_agences = [];
                var all_dates = [];

                if(this.radio == 'jour'){
                    this.data1 = [];
                    this.data2 = [];
                    
                    data.forEach(val => {
                        
                        let jour = moment(new Date(val.jour)).format('DD/MM/YYYY');
                        if (!all_agences.includes(val.nom)) {
                            all_agences.push(val.nom);
                        }
                        if (!all_dates.includes(jour)) {
                            all_dates.push(jour);
                        }
                    });

                    all_agences.forEach(agence => {

                        var Array_min = [];

                        all_dates.forEach(date => {
                            
                            let minutes = 0;

                            data.forEach(element => {

                                if (element.nom == agence) {

                                    if(date == moment(new Date(element.jour)).format('DD/MM/YYYY')){
                                        minutes = minutes + parseInt(element.minute);
                                    }
                                }
                            });

                            Array_min.push(minutes);
                        });

                        var serie =
                        {
                            'name': agence,
                            'data': Array_min,
                        };
                        this.data2.push(serie);
                    });

                    this.data1 = all_dates;
                }

                else if(this.radio == 'mois') {
                    
                    this.data1 = [];
                    this.data2 = [];
                    var all_agences = [];

                    data.forEach(element => {
                        if(!this.data1.includes(element.mois_texte)){this.data1.push(element.mois_texte)}
                        if (!all_agences.includes(element.nom)) {all_agences.push(element.nom);}
                    });

                    all_agences.forEach(agence => {

                        var Array_min = [];

                        this.data1.forEach(mois => {
                            
                            let minutes = 0;

                            data.forEach(element => {

                                if (element.nom == agence) {

                                    if(mois == element.mois_texte){
                                        minutes = minutes + parseInt(element.minute);
                                    }
                                }
                            });

                            Array_min.push(minutes);
                        });

                        var serie =
                        {
                            'name': agence,
                            'data': Array_min,
                        };

                        this.data2.push(serie);
                    });

                }


            },

            BuildTable(data) {
                
                data.forEach(element => {
                    element.jour = moment(new Date(element.jour)).format('DD/MM/YYYY');
                    element.date = moment(new Date(element.date)).format('HH:mm:ss');

                    let crm = {
                        jour: element.jour,
                        date : element.date,
                        minute: element.minute,
                        name: element.name,
                        agence: element.nom,
                      };
                    
                      this.items.push(crm);
                });

                // this.datatable = data;
            },
        }


    }

</script>