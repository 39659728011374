<template>
    <div class="m-2">

    <div class="row" >
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom">
            <label class="mb-2"> Format d'affichage: </label>
            <div>
                <el-radio v-model="radio" label="jour">Jours</el-radio>
                <!-- <el-radio v-model="radio" label="semaine">Semaines</el-radio> -->
                <el-radio v-model="radio" label="mois">Mois</el-radio>
            </div>
        </div>
        <div v-if="radio== 'jour'" class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-xs-6 mb-1">
                <label>Choisir la période : </label>
                <div class="data-picker" style="width:100%">
                    <el-date-picker
                        v-model="drp_jour"
                        type="daterange"
                        unlink-panels
                        range-separator="-"
                        format="dd/MM/yyyy"
                        @change="load()"
                        :clearable="clearable"
                        :picker-options="pickerOptions_j">
                    </el-date-picker>
                </div>
        </div>
        <div v-if="radio== 'mois'" class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-xs-6 mb-1">
                <label>Choisir la période : </label>
                <div class="data-picker" style="width:100%">
                    <el-date-picker
                        v-model="drp_month"
                        format="dd/MM/yyyy"
                        type="monthrange"
                        align="right"
                        unlink-panels
                        range-separator="à"
                        start-placeholder="Mois de début"
                        end-placeholder="Mois de fin"
                        :picker-options="pickerOptions_m">
                    </el-date-picker>
                </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom">
            <label class="mb-2">{{titleChoice}} : </label>
            <div class="data-picker" style="width:100%">
                <ui-select
                    :placeholder="titleChoice"
                    :options="optionsSelect"
                    v-model="optionsSelected"
                ></ui-select>
            </div>
        </div>
    </div>
        
    </div>
</template>

<script>

    import moment from 'moment';
    

    export default {

        props: ['type','titleChoice','optionsSelect','optionsSelected','drp_jour','drp_month','load'],

        data () {
            return {
                radio: 'jour',
                status: '',
                statusSelect : [],
                periode: 'Choisir la periode',
                start:  moment().subtract(12, 'month').startOf('month').toDate(),
                end: moment().toDate(),
                clearable: true,
                // drp_jour : [moment().subtract(1, 'isoweek').startOf('isoweek').toDate(), moment().toDate()],
                // drp_month:[moment().subtract(4, 'month').endOf('month').toDate(), moment().endOf('month').toDate()],
                
                pickerOptions_j: {
                    shortcuts: [{
                        text: 'Mois precedent',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]);
                        }
                    }, 
                    {
                        text: 'Mois en cours',
                        onClick(picker) {
                            picker.$emit('pick', [moment().startOf('month').toDate(), new Date()]);
                        }
                    }, 
                    {
                        text: 'Semaine precedente',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'week').startOf('isoWeek').toDate(), moment().subtract(1, 'week').endOf('isoWeek').toDate()]);
                        }
                    }, 
                    {
                        text: 'Semaine en cours',
                        onClick(picker) {
                            picker.$emit('pick', [moment().startOf('isoWeek').toDate(), new Date()]);
                        }
                    }, 
                    {
                        text:'Avant hier',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(2, 'day').toDate(), moment().subtract(2, 'day').toDate()]);
                        }
                    }, 
                    {
                        text: 'Hier',
                        onClick(picker) {
                            picker.$emit('pick', [moment().subtract(1, 'day').toDate(), moment().subtract(1, 'day').toDate()]);
                        }
                    }, {
                        text: 'Aujoudhuit',
                        onClick(picker) {
                            picker.$emit('pick', [moment().toDate(), new Date()]);
                        }
                    }],
                    format:'dd MM yyyy',
                    firstDayOfWeek: 1,
                    disabledDate: this.disabledDueDate
                },

                pickerOptions_m: {
                    shortcuts: [{
                        text: 'Ce mois',
                        onClick(picker) {
                        picker.$emit('pick', [moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
                        }
                    }, {
                        text: 'Cette année',
                        onClick(picker) {
                        picker.$emit('pick', [moment().startOf('year').toDate(), moment().endOf('month').toDate()]);
                        }
                    }, {
                        text: 'Les derniers 6 mois',
                        onClick(picker) {
                        picker.$emit('pick', [moment().subtract(5, 'month').startOf('month').toDate(), moment().toDate()]);
                        }
                    }],
                    format:'dd MM yyyy',
                    firstDayOfWeek: 1,
                    disabledDate: this.disabledDueDate
                },
            };
        },
        
        methods: {
            disabledDueDate(date) {   
                return !(date >= this.start && date <= this.end)
            },

        },

        // mutations: {
        //     increment (state) {
        //     // mutate state
        //     state.count++
        //     }
        // },

        // computed: {

        //     drp_month(){
        //         console.log('mois');
        //         this.drp = this.drp_month;
        //     }
        // },

        // watch: {

        //     drp_jour : function() {
        //         this.drp = this.drp_month;
        //     },

        //     drp_month : function() {
        //         this.drp = this.drp_month;
        //     }
        // },


        created() {

        },

        mounted () {
            console.log(this.type);
        }
    }
</script>