<template>
  <div>
      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3" >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{title}}
            </div>

            <div class="subtitle-1 font-weight-light">
              <!-- New employees on 15th September, 2016 -->
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>
      </v-col>
  </div>
</template>

<script>


  export default {
    
    props: ['title','headers','items'],
  
  }
</script>