<template>
    <div class="row mr-5 p-2">
        <apexchart height ="350px" type="bar" :options="options" :series="series"></apexchart>
    </div>
</template>
<script>

    import VueApexCharts from 'vue-apexcharts';

    export default {

        components: {
            'apexchart': VueApexCharts
        },

        props: ['data1','data2'],

        data () {
            
            return {

                series: [ ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'Durée en minutes'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return val + " minutes"
                            }
                        }
                    }
                },
            }
        },

        watch: {
            data1 : function() {
                this.options = {...this.options, ...{
                    xaxis: {
                        categories: this.data1,
                    }
                }}
                this.series = this.data2; 
            },
        },

        mounted() {
            this.series = this.data2;
            this.options = {...this.options, ...{
                    xaxis: {
                        categories: this.data1,
                    }
            }}
        }
    }
</script>